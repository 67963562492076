export default {
  image: {
    main: {},
  },
  about: {
    details: [
      {
        type: "html",
        data: `
          <h3>Мы&nbsp;предоставляем полный спектр услуг,<br>от проектирования до&nbsp;полного внедрения систем автоматизации,<br>а так&nbsp;же дальнейшего сервисного обслуживания.</h3>
          <ul>
<li>Подготовка технических решений для задач автоматизации</li>
<li>Подбор необходимого оборудования и&nbsp;комплектующих</li>
<li>Проектирование и&nbsp;изготовление шкафов управления, согласно техническому заданию</li>
<li>Проектирование и&nbsp;внедрение автоматизированных систем управления</li>
<li>Визуализация технологических процессов</li>
<li>Модернизация производственных процессов</li>
<li>Автоматизация и&nbsp;диспетчеризация инженерных систем</li>
<li>Консультация и&nbsp;обучение персонала заказчика</li>
          </ul>
        `,
      },
    ],
  },
};
