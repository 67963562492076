export default {
  id: "status-io",
  name: "STATUS-IO",
  about: {
    description: [
      [
        {
          type: "html",
          data: `
            <p>предназначен для ввода аналоговых значений, дискретных значений, Вывода аналоговых значений, управления оконечными устройствами</p>
          `,
        },
      ],
      [
        {
          type: "html",
          data: `
            <ul>
              <li>Ввод аналоговых значений с&nbsp;промышленных интерфейсов 0-20mA, 0-10V, дискретного входа.</li>
              <li>Вывода аналоговых значений на&nbsp;интерфейс 0-20mA, 0-10V.</li>
              <li>Управление оконечными устройствами через встроенные реле</li>
              <li>Информация с&nbsp;входов, информация для выходов и&nbsp;команды управления реле передаются по&nbsp;промышленному интерфейсу MODBUS RTU с&nbsp;физическим уровнем RS-485.</li>
            </ul>
          `,
        },
      ],
    ],
    specifications: [
      {
        name: "Характеристики",
        data: [
          {
            type: "html",
            data: `
              <ul>
                <li>6&nbsp;аналоговых входов. Могут быть настроены как входы 0-20mA, 0-10V или дискретный вход 0/24V индивидуально с&nbsp;помощью DIP переключателей;</li>
                <li>4&nbsp;аналоговых выхода. Могут быть настроены как выход 0-20mA или 0-10V индивидуально с&nbsp;помощью DIP переключателей;</li>
                <li>7&nbsp;выходов сухих контактов (реле) с&nbsp;общим COM. Могут быть разбиты на&nbsp;две группы по 3&nbsp;шт. Контакты на&nbsp;замыкание (нормально разомкнут);
                <li>Интерфейс RS485&nbsp;MODBUS RTU;</li>
                <li>Вход питания контроллера 24&nbsp;вольт постоянного тока;</li>
                <li>Светодиодная индикация наличия питания и&nbsp;режимов работы.</li>
              </ul>
            `,
          },
        ],
      },
    ],
  },
};
