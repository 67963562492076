export default {
  id: "metro-c-c",
  name: "Metro C&C",
  location: "г. Белгород",
  year: 2019,

  page: {
    facility: {
      about: [
        {
          title: "В&nbsp;ходе реализации проекта были выполнены работы:",
          content: [
            {
              type: "html",
              data: `
                <ul>
                  <li>
                    <p>Автоматизации и&nbsp;диспетчеризация следующих систем торгового комплекса:</p>
                    <ul>
                      <li>Вентиляция (приточная и&nbsp;вытяжная) и&nbsp;кондиционирование</li>
                      <li>Холодоснабжение</li>
                      <li>Электроснабжение и&nbsp;электроосвещение</li>
                      <li>Котельная и&nbsp;ДГУ</li>
                    </ul>
                    <p>Управление системами и&nbsp;отображение информации осуществляется на&nbsp;сенсорной панели оператора.</p>
                    <p>Система автоматизации и&nbsp;диспетчеризации насчитывает более 700 сигналов и&nbsp;реализована на&nbsp;базе оборудования SIEMENS.</p>
                    <p>При реализациисистемы управления используются самонастраивающиеся с&nbsp;элементами управления Fuzzy logic.</p>
                  </li>
                  <li>Сборка шкафов автоматизации на&nbsp;базе контроллеров с&nbsp;распределенной периферией SIEMENS и&nbsp;пускорегулирующей аппаратуры Schneider Electric.</li>
                  <li>Выполнен монтаж оборудования, приборов КИПиА</li>
                  <li>Осуществлена прокладка около 18&nbsp;км кабельно-проводниковой продукции.</li>
                  <li>Пусконаладочные работы системы автоматизации.</li>
                </ul>
              `,
            },
          ],
        },
      ],
    },
  },
};
