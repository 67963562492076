import { PrivacyPolicy } from "components/pages";

const route = {
  path: "/privacy-policy",
  exact: true,
  component: PrivacyPolicy,
  className: "privacy-policy",
};

export default route;
