export default {
  id: "llc-neftebitum-kngk",
  name: "ООО Нефтебитум-КНГК",
  location: "Краснодарский край\nпгт. Ильский",
  year: 2019,

  page: {
    facility: {
      gallery: [{}, {}, {}],
      about: [
        {
          title: "В&nbsp;ходе реализации проекта были выполнены работы:",
          content: [
            {
              type: "html",
              data: `
                  <ul>
                    <li>Изготовление комплектной автоматической насосной станции пожаротушения в&nbsp;блок-контейнере.</li>
                    <li>Подбор оборудования и&nbsp;сборка шкафов управления.</li>
                    <li>Поставка автоматической пожарной насосной станции в&nbsp;составе блок-контейнера.</li>
                    <li>Поставка системы автоматизации и&nbsp;управления пенного пожаротушения.</li>
                  </ul>
                `,
            },
          ],
        },
      ],
    },
  },
};
