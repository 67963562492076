export default {
  id: "status-p",
  name: "STATUS-P",
  image: {
    main: {},
  },
  about: {
    description: [
      [
        {
          type: "html",
          data: `
            <p>Панель оператора STATUS-P предназначена для отображения значения технологических параметров, с&nbsp;возможностью управления и&nbsp;изменения параметров.</p>
          `,
        },
      ],
      [
        {
          type: "html",
          data: `
            <ul>
              <li>Эконом регулирование (управление одним частотным преобразователем, отображение и&nbsp;настройка технологических параметров, бесступенчатое поддержание контролируемого параметра)</li>
              <li>Мультичастотное регулирование (управление несколькими частотными преобразователями , отображение и&nbsp;настройка технологических параметров, бесступенчатое поддержание контролируемого параметра)</li>
              <li>Функции операторской панели (Отображение и&nbsp;настройка параметров основного устройства. Основным устройством может быть контроллер управления STATUS-IV, либо частотный преобразователь )</li>
            </ul>
          `,
        },
      ],
    ],
    specifications: [
      {
        name: "Основные функции",
        data: [
          {
            type: "html",
            data: `
              <ul>
                <li>Устройство предназначено для работы в&nbsp;составе шкафов управления насосными станциями.</li>
                <li>Гибкие возможности настройки устройства</li>
                <li>Отображение в&nbsp;реальном времени контролируемых технологических параметров</li>
                <li>Возможность настройки технологических параметров и&nbsp;параметров ПЧ</li>
                <li>Возможность управления частотными преобразователями</li>
                <li>Индикация текущих аварий</li>
              </ul>
            `,
          },
        ],
      },
      {
        name: "Подробное описание",
        data: [
          {
            type: "html",
            data: `
              <p>ПО&nbsp;поддерживает следующие алгоритмы работы:</p>
              <ul>
                <li>Эконом регулирование (управление одним частотным преобразователем, отображение и&nbsp;настройка технологических параметров, бесступенчатое поддержание контролируемого параметра)</li>
                <li>Мультичастотное регулирование (управление несколькими частотными преобразователями , отображение и&nbsp;настройка технологических параметров, бесступенчатое поддержание контролируемого параметра)</li>
                <li>>Функции операторской панели(Отображение и&nbsp;настройка параметров основного устройства. Основным устройством может быть контроллер управления STATUS-IV, либо частотный преобразователь )</li
              </ul>
              <p>Требуемое значение контролируемого параметра задаётся пользователем с&nbsp;панели оператора. Текущее значение контролируемого параметра могут быть отображены на&nbsp;панели оператора. Также на&nbsp;панели оператора предусмотрены светодиодные индикаторы соответствующие определённым режимам работы.</p>
              <p>Контроллер обеспечивает отображение аварийных ситуаций в&nbsp;следующих случаях:</p>
              <ul>
                <li>отсутствие воды</li>
                <li>обрыв аналогового датчика сухого хода</li>
                <li>обрыв аналогового датчика давления</li>
                <li>потеря связи контроллера с&nbsp;преобразователем частоты</li>
                <li>аварии, связанные с&nbsp;подачей питания</li>
                <li>запрет работы станции с&nbsp;диспетчерского пульта, станции пожаротушения либо других контролирующих систем</li>
                <li>защита электродвигателей (мотор-автоматы, тепловые реле либо другие способы в&nbsp;зависимости от&nbsp;комплектации автоматической системы)</li>
              </ul>
              <p>Для управления технологическим оборудованием панель оператора оснащена встроенным интерфейсом связи RS-485&nbsp;с поддержкой протокола MODBUS RTU. Панель оператора может быть использована в&nbsp;различных шкафах управления .</p>
            `,
          },
        ],
      },
    ],
  },
};
