export default {
  id: "hydro-gk",
  name: "Hydro GK",
  type: "стандартный",
  image: {
    main: {},
  },
  about: {
    description: [
      [
        {
          type: "text",
          data:
            "предназначены для перекачивания, водоотведения и&nbsp;поддержания уровня жидкости в&nbsp;системах водоотведения &mdash; промышленности, ЖКХ, сельского хозяйства.",
        },
      ],
    ],
    specifications: [
      {
        name: "Область применения",
        data: [
          [
            {
              type: "list",
              data: [
                "Системы поддержания уровня",
                "Водоснабжение зданий, спортивных объектов",
                "Системы перекачивания жидкостей",
              ],
            },
            {
              type: "list",
              data: [
                "Канализационные системы",
                "Водоотведение промышленных объектов",
                "Станции перекачки сточных вод",
              ],
            },
          ],
        ],
      },
      {
        name: "Основные функции",
        data: [
          {
            type: "list",
            data: [
              "Поддержание заданного давления в&nbsp;выходном трубопроводе;",
              "Автоматическое изменение производительности при изменении водоразбора;",
              "Чередование насосов для равномерного износа и&nbsp;увеличения ресурса.",
            ],
          },
        ],
      },
      {
        name: "Отличительные особенности",
        data: [
          {
            type: "list",
            data: ["Дренчерные", "Спринклерные", "Спринклерно-дренчерные"],
          },
        ],
      },
      {
        name: "Технические данные",
        data: [
          {
            type: "list",
            data: [
              "Поддержание заданного уровня",
              "Автоматическое возобновление работы после устранения неисправности",
              "Защита насосов от&nbsp;работы без воды",
              "Защита от&nbsp;обрыва, асимметрии и&nbsp;неправильного чередования фаз",
              "Защита насосов от&nbsp;перегрузки по&nbsp;току и&nbsp;короткого замыкания",
              "Контроль протечки насосов",
              "Низкая стоимость, по&nbsp;сравнению с&nbsp;оригинальными станциями ABS, Grundfos, KSB, Wilo,FLYGT",
            ],
          },
        ],
      },
      {
        name: "Дополнительные опции",
        data: [
          {
            type: "list",
            data: [
              "Диспетчеризация сухими контактами;",
              "Встроенный АВР",
              "Диспетчеризация по&nbsp;интерфейсу RS485",
              "Устройства плавного пуска",
              "Контроль протечки насосов",
              "Аналоговый датчик уровня",
            ],
          },
        ],
      },
    ],
  },
};
