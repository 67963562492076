import { DOCUMENTS_PATH } from "config/path";
import { getProductManualSrc } from "utils/path";

export default {
  id: "c-status-iv",
  name: "STATUS-IV",
  image: {
    main: {},
  },
  about: {
    description: [
      [
        {
          type: "html",
          data:
            "<p>предназначен для создания систем автоматизации технологических процессов, связанных с&nbsp;поддержанием заданного значения технологического параметра, с&nbsp;возможностью удаленного мониторинга, управления и&nbsp;изменения параметров.</p>",
        },
      ],

      [
        {
          type: "html",
          data: `
            <ul>
              <li>Каскадный алгоритм регулирования&nbsp;&mdash; ступенчатое поддержание контролируемого параметра</li>
              <li>Разработан для управления автоматическими насосными установками</li>
              <li>Обеспечивает отслеживание аварийных ситуаций и&nbsp;защиту оборудования</li>
              <li>Контроль времени наработок насосов и&nbsp;их&nbsp;периодическое чередование с&nbsp;целью равномерного износа</li>
              <li>Широкие возможности для настройки под различные комплектации станций</li>
            </ul>
          `,
        },
      ],
    ],
    specifications: [
      {
        name: "Подробное описание",
        data: [
          {
            type: "html",
            data: `
              <p>В зависимости от версии ПО поддерживает следующие алгоритмы регулирования:</p>
              <ul>
                <li>Каскадный (ступенчатое поддержание контролируемого параметра)</li>
                <li><u>Один преобразователь частоты</u> (бесступенчатое поддержание контролируемого параметра)</li>
                <li>Поплавковый</li>
              </ul>
              <p>Требуемое значение контролируемого параметра задаётся пользователем с&nbsp;панели контроллера.</p>
              <p>Текущее значение контролируемого параметра, значение выходной частоты&nbsp;ПЧ и&nbsp;другие технологические параметры могут быть отображены на&nbsp;панели контроллера. Также на&nbsp;панели контроллера предусмотрены светодиодные индикаторы, соответствующие определённым режимам работы.</p>
              <p>Для управления технологическим оборудованием контроллер оснащен встроенными реле в количестве одиннадцати штук.</p>
              <p>Одно (по&nbsp;умолчанию) настроено для управления светосигнальной или прочей аппаратурой для сигнализации о&nbsp;возникновении аварийных ситуаций.</p>
              <p>Остальные десять реле настроены для управления контакторами, коммутирующими цепи питания электродвигателей (5&nbsp;&mdash; для подключения электродвигателей к&nbsp;ПЧ, и&nbsp;5&nbsp;&mdash; для подключения напрямую к&nbsp;питающей сети).</p>
              <p>Входам и&nbsp;выходам контроллера могут назначаться и&nbsp;другие функции.</p>
            `,
          },
        ],
      },
      {
        name: "Случаи применения",
        data: [
          {
            type: "html",
            data: `
              <ul>
                <li>Отсутствие воды;</li>
                <li>Обрыв аналогового датчика сухого хода;</li>
                <li>Обрыв аналогового датчика давления;</li>
                <li>Потеря связи контроллера с&nbsp;преобразователем частоты;</li>
                <li>Аварии, связанные с&nbsp;подачей питания;</li>
                <li>Запрет работы станции с&nbsp;диспетчерского пульта, станции пожаротушения либо других контролирующих систем;</li>
                <li>Защита электродвигателей (мотор-автоматы, тепловые реле либо другие способы в зависимости от&nbsp;комплектации автоматической системы).</li>
              </ul>
            `,
          },
        ],
      },
      {
        name: "Документация",
        data: [
          {
            type: "cards",
            data: [
              {
                type: "documentation",
                data: {
                  caption: "руководство по эксплуатации",
                  title: "STATUS-IV",
                  file: getProductManualSrc({
                    categoryId: "electronics",
                    subcategoryId: "controllers",
                    productId: "status-iv",
                  }),
                },
              },
            ],
          },
        ],
      },
    ],
  },
};
