export default {
  id: "hydro-gc",
  name: "Hydro GC",
  type: "каскадное регулирование",
  image: {
    main: {},
  },
  about: {
    description: [
      [
        {
          type: "text",
          data:
            "предназначены для перекачивания, повышения и&nbsp;поддержания давления в&nbsp;системах водоснабжения&nbsp;&mdash; промышленности, ЖКХ, сельского хозяйства.",
        },
      ],
    ],
    specifications: [
      {
        name: "Область применения",
        data: [
          [
            {
              type: "list",
              data: [
                "Системы повышения давления",
                "Водоснабжение зданий, спортивных объектов",
                "Ирригационные системы",
              ],
            },
            {
              type: "list",
              data: [
                "Системы повышения давления",
                "Водоснабжение зданий, спортивных объектов",
                "Ирригационные системы",
              ],
            },
          ],
        ],
      },
      {
        name: "Основные функции",
        data: [
          {
            type: "list",
            data: [
              "Поддержание заданного давления в&nbsp;выходном трубопроводе;",
              "Защита от&nbsp;обрыва, асимметрии и&nbsp;неправильного чередования фаз;",
              "Защита насосов от&nbsp;работы без воды;",
              "Защита насосов от&nbsp;перегрузки по&nbsp;току и&nbsp;короткого замыкания;",
              "Автоматическое изменение возобновление работы после устранения неисправности;",
              "Низкая стоимость, по&nbsp;сравнению с&nbsp;оригинальными станциями ABS, Grundfos, KSB, Wilo, FLYGT.",
            ],
          },
        ],
      },
      {
        name: "Дополнительные опции",
        data: [
          {
            type: "list",
            data: [
              "Диспетчеризация сухими контактами;",
              "Встроенный АВР;",
              "Устройства плавного пуска.",
            ],
          },
        ],
      },
    ],
  },
};
