export const GOOGLE_MAPS_API_KEY = "AIzaSyCfXYgfUNO7nKDxDYbUiLgLokgiojKamls";

export const AppColor = {
  BLUE: "#0625C8",
  RED: "#C81006",
  DARK_RED: "#9D0C04",
  DARK_GREEN: "#014701",
  YELLOW: "#E4C606",
  GRAY: "#D4D4D4",
  BODY: "#f2f2f2",
};

export const Theme = {
  DARK: "dark",
  LIGHT: "light",
};

export const IS_COMPETITION_VERSION = false;
