export default {
  id: "cjsc-yoshkar-ola-meat-processing-plant",
  name: "ЗАО Йошкар-Олинский мясокомбинат",
  location: "г. Владимир",
  year: 2019,

  page: {
    facility: {
      gallery: [{}, {}, {}, {}, {}],
      about: [
        {
          title: "В&nbsp;ходе реализации проекта были выполнены работы:",
          content: [
            {
              type: "html",
              data: `
                  <ul>
                    <li>В&nbsp;рамках проекта по&nbsp;строительству локальных очистных сооружений ЗАО &laquo;Йошкар-Олинский мясокомбинат&raquo;, были поставлены шкафы автоматизации и&nbsp;управления.</li>
                    <li>Осуществление комплексных решений по&nbsp;электроснабжению инженерных систем локальных очистных сооружений.</li>
                    <li>Осуществление сбора и&nbsp;передачи сигналов диспетчеризации на&nbsp;АРМ оператора с&nbsp;технологического оборудования, установок прочих инженерных систем.</li>
                    <li>Управление технологическим оборудованием цикла очистки сточных вод.</li>
                  </ul>
                `,
            },
          ],
          image: {
            file: "facility-about.jpg",
          },
        },
      ],
    },
  },
};
