export default {
  id: "gc-yugstroyinvest",
  name: "ГК&nbsp;ЮгСтройИнвест",
  location: "Краснодар\nРостов-на-Дону\nСтаврополь",
  year: 2019,
  page: {
    facility: {
      aside: {},
      about: [
        {
          title: "В&nbsp;ходе реализации проекта были изготовлены",
          content: [
            {
              type: "html",
              data: `
                  <ul>
                    <li>Насосные станции водоснабжения жилых домов (более 30 насосных станций)</li>
                    <li>Квартальные насосные станции водоснабжения возводимых микрорайонов</li>
                    <li>Шкафы управления канализационными насосными станциями</li>
                    <li>Комплектные канализационные насосные станции с&nbsp;системой управления и&nbsp;диспетчеризации</li>
                  </ul>
                `,
            },
          ],
          image: {
            file: "facility-about.jpg",
          },
        },
      ],
    },
  },
};
