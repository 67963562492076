export default [
  {
    name: "Генеральный директор\nЕвгений Михайлович Еременко",
    links: [
      {
        label: "+7(863)308-90-90",
        href: "tel:+7(863)308-90-90",
      },
      {
        label: "e.eremenko@globe-it.ru",
        href: "mailto:e.eremenko@globe-it.ru",
      },
    ],
  },
  {
    name: "Специалист отдела продаж\nАнтон Маркович Титаренко",
    links: [
      {
        label: "+7(863)308-90-90 доб.201",
        href: "tel:+7(863)308-90-90,201",
      },
      {
        label: "+7(988)580-10-61",
        href: "tel:+7(988)580-10-61",
      },
      {
        label: "sales@globe-it.ru",
        href: "mailto:sales@globe-it.ru",
      },
    ],
  },
  {
    name: "Специалист отдела продаж\nДмитрий Николаевич Яковлев",
    links: [
      {
        label: "+7(863)308-90-90 доб.202",
        href: "tel:+7(863)308-90-90,202",
      },
      {
        label: "sales2@globe-it.ru",
        href: "mailto:sales2@globe-it.ru",
      },
    ],
  },
  {
    name: "Специалист отдела продаж\nАндрей Николаевич Мартынов",
    links: [
      {
        label: "+7(863)308-90-90 доб.206",
        href: "tel:+7(863)308-90-90,206",
      },
      {
        label: "man@globe-it.ru",
        href: "mailto:man@globe-it.ru",
      },
    ],
  },
  {
    name: "Руководитель проекта\nАлександр Иванович Марахов",
    links: [
      {
        label: "+7(863)308-90-90 доб.208",
        href: "tel:+7(863)308-90-90,208",
      },
      {
        label: "project@globe-it.ru",
        href: "mailto:project@globe-it.ru",
      },
    ],
  },
  {
    name: "Руководитель отдела тендерных продаж\nДмитрий Викторович Лодин",
    links: [
      {
        label: "+7(863)308-90-90 доб.300",
        href: "tel:+7(863)308-90-90,300",
      },
      {
        label: "torgi@globe-it.ru",
        href: "mailto:torgi@globe-it.ru",
      },
    ],
  },
  {
    name: "Руководитель инженерного отдела\nАртем Валерьевич Кибасов",
    links: [
      {
        label: "+7(863)308-90-90 доб.400",
        href: "tel:+7(863)308-90-90,400",
      },
      {
        label: "+7(928)181-53-09",
        href: "tel:+7(928)181-53-09",
      },
      {
        label: "kav@globe-it.ru",
        href: "mailto:kav@globe-it.ru",
      },
    ],
  },
  {
    name: "Специалист сервисного отдела\nАртем Евгеньевич Пьянов",
    links: [
      {
        label: "+7(863)308-90-90 доб.403",
        href: "tel:+7(863)308-90-90,403",
      },
      {
        label: "+7(928)604-71-47",
        href: "tel:+7(928)604-71-47",
      },
      {
        label: "service@globe-it.ru",
        href: "mailto:service@globe-it.ru",
      },
    ],
  },
  {
    name: "Руководитель электрощитового цеха\nИгорь Викторович Блинов",
    links: [
      {
        label: "+7(863)308-90-90 доб.601",
        href: "tel:+7(863)308-90-90,601",
      },
      {
        label: "electric.department@globe-it.ru",
        href: "mailto:electric.department@globe-it.ru",
      },
    ],
  },
  {
    name: "Руководитель цеха металлоконструкций\nВладимир Юрьевич Кобищанов",
    links: [
      {
        label: "+7(863)308-90-90 доб.700",
        href: "tel:+7(863)308-90-90,700",
      },
      {
        label: "kvu@globe-it.ru",
        href: "mailto:kvu@globe-it.ru",
      },
    ],
  },
  {
    name: "Главный бухгалтер\nОльга Алексеевна Акчурина",
    links: [
      {
        label: "+7(863)308-90-90 доб.800",
        href: "tel:+7(863)308-90-90,800",
      },
    ],
  },
  {
    name: "Бухгалтер\nАнна Владимировна Попкова",
    links: [
      {
        label: "+7(863)308-90-90 доб.804",
        href: "tel:+7(863)308-90-90,804",
      },
      {
        label: "a.popkova@globe-it.ru",
        href: "mailto:a.popkova@globe-it.ru",
      },
    ],
  },
  {
    name: "Бухгалтер\nАлла Геннадьевна Словецкая",
    links: [
      {
        label: "+7(863)308-90-90 доб.803",
        href: "tel:+7(863)308-90-90,803",
      },
      {
        label: "a.slov@globe-it.ru",
        href: "mailto:a.slov@globe-it.ru",
      },
    ],
  },
  {
    name: "Помощник бухгалтера\nЕлена Игоревна Игнатенко",
    links: [
      {
        label: "+7(863)308-90-90 доб.801",
        href: "tel:+7(863)308-90-90,801",
      },
      {
        label: "+7(928)151-52-17",
        href: "tel:+7(928)151-52-17",
      },
      {
        label: "buh@globe-it.ru",
        href: "mailto:buh@globe-it.ru",
      },
    ],
  },
  {
    name: "Специалист по снабжению\nАлександра Ярославовна Ведяйкина",
    links: [
      {
        label: "+7(863)308-90-90 доб.809",
        href: "tel:+7(863)308-90-90,809",
      },
      {
        label: "+7(918)540-18-80",
        href: "tel:+7(918)540-18-80",
      },
      {
        label: "snab@globe-it.ru",
        href: "mailto:snab@globe-it.ru",
      },
    ],
  },
  {
    name: "Специалист по подбору персонала\nПолина Викторовна Клименко",
    links: [
      {
        label: "+7(863)308-90-90 доб.802",
        href: "tel:+7(863)308-90-90,802",
      },
      {
        label: "hr@globe-it.ru",
        href: "mailto:hr@globe-it.ru",
      },
    ],
  },
];
