import common from "./translates/common.json";
import pages from "./translates/pages.json";
import complex from "./translates/complex.json";
import facilities from "./translates/facilities.json";

const locale = {
  common,
  pages,
  facilities,
  complex,
};

export default locale;
