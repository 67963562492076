import { DOCUMENTS_PATH } from "config/path";
import { getProductManualSrc } from "utils/path";

export default {
  id: "c-status-u",
  name: "STATUS-U",
  image: {
    main: {},
  },
  about: {
    description: [
      [
        {
          type: "html",
          data:
            "<p>Устройство предназначено для защиты электродвигателя или электроустановки питаемой от&nbsp;трехфазной сети в&nbsp;случаях:</p>",
        },
      ],
      [
        {
          type: "html",
          data: `
            <ul>
              <li>Отсутствии хотя&nbsp;бы одной из&nbsp;фаз (&laquo;обрыв фаз&raquo;)</li>
              <li>Превышение напряжения выше установленного порога</li>
              <li>Уменьшение напряжения ниже установленного порога</li>
              <li>Обрыва нейтрали</li>
              <li>Нарушении чередования фаз</li>
              <li>Слипания и&nbsp;перекоса фаз</li>
            </ul>
          `,
        },
      ],
    ],
    specifications: [
      {
        name: "Подробное описание",
        data: [
          {
            type: "html",
            data: `
              <p>В зависимости от версии ПО поддерживает следующие алгоритмы регулирования:</p>
              <ul>
                <li>Каскадный (ступенчатое поддержание контролируемого параметра)</li>
                <li>Один преобразователь частоты (бесступенчатое поддержание контролируемого параметра)</li>
                <li>Поплавковый</li>
              </ul>
              <p>Требуемое значение контролируемого параметра задаётся пользователем с&nbsp;панели контроллера.</p>
              <p>Текущее значение контролируемого параметра, значение выходной частоты&nbsp;ПЧ и&nbsp;другие технологические параметры могут быть отображены на&nbsp;панели контроллера. Также на&nbsp;панели контроллера предусмотрены светодиодные индикаторы, соответствующие определённым режимам работы.</p>
              <p>Для управления технологическим оборудованием контроллер оснащен встроенными реле в количестве одиннадцати штук.</p>
              <p>Одно (по&nbsp;умолчанию) настроено для управления светосигнальной или прочей аппаратурой для сигнализации о&nbsp;возникновении аварийных ситуаций.</p>
              <p>Остальные десять реле настроены для управления контакторами, коммутирующими цепи питания электродвигателей (5&nbsp;&mdash; для подключения электродвигателей к&nbsp;ПЧ, и&nbsp;5&nbsp;&mdash; для подключения напрямую к&nbsp;питающей сети).</p>
              <p>Входам и&nbsp;выходам контроллера могут назначаться и&nbsp;другие функции.</p>
            `,
          },
        ],
      },
      {
        name: "Отличительные особенности",
        data: [
          {
            type: "html",
            data: `
              <ul>
                <li>Гибкие возможности настройки устройства</li>
                <li>Возможность отслеживания в&nbsp;режиме онлайн параметров трехфазной сети</li>
                <li>Возможность считывания лог-файлов событий</li>
                <li>Светодиодная индикация текущих аварий</li>
              </ul>
            `,
          },
        ],
      },
      {
        name: "Документация",
        data: [
          {
            type: "cards",
            data: [
              {
                type: "documentation",
                data: {
                  caption: "руководство по эксплуатации",
                  title: "STATUS-U",
                  file: getProductManualSrc({
                    categoryId: "electronics",
                    subcategoryId: "controllers",
                    productId: "status-u",
                  }),
                },
              },
            ],
          },
        ],
      },
    ],
  },
};
