export default {
  id: "status-32-64",
  name: "STATUS-32_64",
  about: {
    description: [
      [
        {
          type: "html",
          data:
            "<p>предназначен для сбора информации о&nbsp;состоянии концевых выключателей и&nbsp;о&nbsp;состоянии линии, управления оконечными устройствами посредством встроенных реле, диспетчеризации через сухие контакты.</p>",
        },
      ],
      [
        {
          type: "html",
          data: `
            <ul>
              <li>Сбор информации и&nbsp;управление оконечными устройствами посредством встроенных реле.</li>
              <li>Наличие входов для подключения концевых выключателей по&nbsp;схеме NAMUR(для контроля&nbsp;КЗ и&nbsp;обрыва линии)</li>
              <li>Наличие двухпроводной линии передачи данных и&nbsp;питающего напряжения для внешних цифровых блоков обработки сигналов концевых выключателей</li>
              <li>Информация со&nbsp;входов и&nbsp;команды управления реле передаются по промышленному интерфейсу MODBUS RTU с&nbsp;физическим уровнем RS-485</li>
            </ul>
          `,
        },
      ],
    ],
    specifications: [
      {
        name: "Характеристики",
        data: [
          {
            type: "html",
            data: `
              <ul>
                <li>32&nbsp;аналоговых входа с&nbsp;диапазоном 0&nbsp;&mdash; 24v</li>
                <li>9&nbsp;выходов сухих контактов (реле) с&nbsp;общим COM. Могут быть разбиты на&nbsp;две группы по 4&nbsp;шт. Контакты на&nbsp;замыкание (нормально разомкнут);</li>
                <li>Интерфейс RS485&nbsp;MODBUS RTU;</li>
                <li>Вход питания контроллера 24v постоянного тока;</li>
                <li>Светодиодная индикация наличия питания и&nbsp;режимов работы;</li>
                <li>Требуемое состояние релейных выходов задаётся через интерфейс RS485&nbsp;MODBUS RTU.</li>
                <li>Состояния входов считываются через интерфейс RS485&nbsp;MODBUS RTU</li>
              </ul>
            `,
          },
        ],
      },
    ],
  },
};
