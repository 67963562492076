export default {
  id: "status-a-2-8",
  name: "STATUS-A-2-8",
  image: {
    main: {},
  },
  about: {
    description: [
      [
        {
          type: "html",
          data: `
            <p>Повторитель аналогового сигнала (1&nbsp;вход, 1&nbsp;выход)</p>
            <p>Повторитель аналогового сигнала STATUS-A 1-1 предназначено для повторения, преобразования и&nbsp;размножения аналоговых сигналов а&nbsp;так&nbsp;же предотвращения возникновения помех на&nbsp;линии.</p>
            <p>Также может применятся в&nbsp;качестве защиты аналогового входа.</p>
          `,
        },
      ],
      [
        {
          type: "html",
          data: `
            <ul>
              <li>Устройство предназначено для повторения, преобразования и&nbsp;размножения аналоговых сигналов</li>
              <li>Гибкие возможности настройки устройства</li>
              <li>Возможность повторения сигналов 0-5В и&nbsp;0-10В</li>
              <li>Возможность повторения сигналов 0-20мА и&nbsp;4-20мА</li>
              <li>Встроенная защита от&nbsp;помех на&nbsp;линии</li>
            </ul>
          `,
        },
      ],
    ],
    specifications: [
      {
        name: "Подробное описание",
        data: [
          {
            type: "html",
            data: `
              <p>ПО&nbsp;поддерживает следующие режимы работы:</p>
              <ul>
                <li>Повторение аналогово сигнала от&nbsp;0&nbsp;до&nbsp;5&nbsp;Вольт и&nbsp;от&nbsp;0&nbsp;до&nbsp;10&nbsp;Вольт</li>
                <li>Повторение аналогово сигнала от&nbsp;0&nbsp;до&nbsp;20мА и&nbsp;от&nbsp;4&nbsp;до&nbsp;20мА</li>
                <li>Преобразование сигнала из&nbsp;0-10В в&nbsp;0-20мА</li>
                <li>Преобразование сигнала из&nbsp;0-20мА а&nbsp;0-10В</li>
                <li>Размножение токового сигнала при использовании сборки из&nbsp;двух и&nbsp;более устройств</li>
              </ul>
              <p>Требуемый режим работы задается пользователем посредством установки джемперов и использовании различных схем подключений устройств.</p>
            `,
          },
        ],
      },
    ],
  },
};
