export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_HOME_ACTIVE_PRODUCT_INDEX = "SET_HOME_ACTIVE_PRODUCT_INDEX";
export const CHANGE_HEADER_THEME = "CHANGE_HEADER_THEME";
export const SET_APP_LOADED = "SET_APP_LOADED";
export const CHANGE_HOME_HERO_THEME = "CHANGE_HOME_HERO_THEME";
export const ENABLE_PRELOADER = "ENABLE_PRELOADER";
export const DISABLE_PRELOADER = "DISABLE_PRELOADER";
export const OPEN_MENU = "OPEN_MENU";
export const CLOSE_MENU = "CLOSE_MENU";
export const CHANGE_HEADER_AND_HOME_HERO_THEME = "CHANGE_HEADER_AND_HOME_HERO_THEME";
export const SET_FACILITIES = "SET_FACILITIES";
