export default {
  id: "control-gf",
  name: "Control GF",
  type: "прямой пуск",
  image: {
    main: {},
  },
  about: {
    description: [
      [
        {
          type: "text",
          data:
            "предназначен для автоматического управления пожарными насосами водяного пожаротушения со&nbsp;стандартными асинхронными электродвигателями с&nbsp;короткозамкнутым ротором согласно сигналам управления.",
        },
      ],
      [
        {
          type: "list",
          data: [
            "Соответствие ГОСТ 53325 гл.7;",
            "Интуитивно понятный интерфейс;",
            "Гибкость настройки;",
            "Оптимальные алгоритмы работы;",
          ],
        },
      ],
    ],
    specifications: [
      {
        name: "Основные функции",
        data: [
          {
            type: "list",
            data: [
              "Защита электродвигателей насосов;",
              "Автоматическое и&nbsp;ручное управление пожарными насосами;",
              "Автоматическое и&nbsp;ручное управление насосом подпитки (опционально);",
              "Автоматическое и&nbsp;ручное управление электродвижками (опционально);",
              "Автоматическое и&nbsp;ручное управление дренажным насосом;",
              "Отсрочка пуска пожарных насосов;",
              "Автоматический пуск основных насосов в&nbsp;соответствии с&nbsp;сигналами управления;",
              "Автоматический пуск резервного насоса в&nbsp;случае отказа или невыхода основного насоса на&nbsp;режим в&nbsp;течение заданного времени;",
              "Ручное отключение автоматического пуска насосов с&nbsp;сохранением возможности ручного пуска;",
              "Отключение хозяйственно питьевых насосов (насосы в&nbsp;комплект поставки не&nbsp;входят);",
              "Индикация состояния системы с&nbsp;помощью световой индикации;",
              "Визуальное отображение состояния системы и&nbsp;настройка необходимых параметров с помощью сенсорной панели оператора;",
              "Звуковая сигнализация состояния &laquo;ПОЖАР&raquo; и&nbsp;&laquo;НЕИСПРАВНОСТЬ&raquo;;",
              "Диспетчеризация состояния системы с&nbsp;помощью сухих контактов (стандартно) или интерфейсной линия связи (опционально).",
            ],
          },
        ],
      },
      {
        name: "Дополнительные опции",
        data: [
          {
            type: "list",
            data: [
              "Предусматривает световую и&nbsp;звуковую сигнализацию, выбор режимов работы и&nbsp;возможность настройки необходимых параметров, диспетчеризацию с&nbsp;помощью сухих контактов (стандартно) или интерфейсной линии связи (опционально).",
              "Настраивается и&nbsp;тестируется на&nbsp;предприятии изготовителе. Оборудование поставляется готовым к&nbsp;подключению и&nbsp;эксплуатации.",
            ],
          },
        ],
      },
    ],
  },
};
