export default {
  image: {
    main: {},
  },
  about: {
    details: [
      {
        type: "html",
        data: `
          <p class="text_secondary-dark text_mono text_lowercase" style="margin-bottom: 60px;">Могут комплектоваться:<br>Станциями водоснабжения, пожаротушения, водоотведения.</p>
          <h3>Зачастую изготавливаются в&nbsp;размерах:</h3>
          <table>
            <tbody>
              <tr>
                <td>
                  Ширина:
                </td>
                <td>
                  2.44 м
                </td>
              </tr>

              <tr>
                <td>
                  Высота:
                </td>
                <td>
                  2.7 м
                </td>
              </tr>

              <tr>
                <td>
                  Длина:
                </td>
                <td>
                  от 2.5-10 м.
                </td>
              </tr>
            </tbody>
          </table>
          <p class="text_secondary-dark text_italic text_mono text_lowercase">
            Также могут быть изготовлены по&nbsp;техническому заданию заказчика.
          </p>
        `,
      },
    ],
    specifications: [
      {
        name: "Область применения",
        data: [
          [
            {
              type: "list",
              data: ["Системы водоснабжения", "Системы водоотведения", "Канализационные системы"],
            },
            {
              type: "list",
              data: [
                "Системы перекачивания жидкостей",
                "Системы циркуляции",
                "Системы пожаротушения",
              ],
            },
          ],
        ],
      },
      {
        name: "Отличительные особенности",
        data: [
          {
            type: "list",
            data: [
              "Изготовлен из&nbsp;качественного металла",
              "Лакокрасочное покрытие устойчивое к&nbsp;воздействиям окружающей среды",
              "Прочная конструкция",
              "Модульность",
              "Качественный утеплитель",
            ],
          },
        ],
      },
      {
        name: "Дополнительные опции",
        data: [
          {
            type: "list",
            data: ["Дополнительное утепление", "Огнезащита", "Аварийное освещение", "Окна"],
          },
        ],
      },
    ],
  },
};
