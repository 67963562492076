import { IMAGES_PATH } from "config/path";

const cards = [
  {
    caption: "лицензия МЧС",
    title: "лицензия МЧС",
    file: "/documents/licenses/moe-license.pdf",
    preview: `${IMAGES_PATH}/license-preview.jpg`,
  },
  {
    caption: "сертификат дилера",
    title: "ООО Энергия",
    file: "/documents/licenses/dealer-certificate-energia-llc.pdf",
    preview: `${IMAGES_PATH}/certificate-preview.jpg`,
  },
];

export default cards;
