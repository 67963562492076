export default {
  id: "status-in-220v",
  name: "STATUS-IN_220V",
  about: {
    description: [
      [
        {
          type: "html",
          data: `
            <p>предназначен для: определения наличия либо отсутствия 220v переменного тока, управления оконечными устройствами,диспетчеризации сухими контактами.</p>
            <p>Определение наличия 220v переменного тока реализовано посредством использования встроенных датчиков наличия 220v переменного тока.</p>
          `,
        },
      ],
      [
        {
          type: "html",
          data: `
            <ul>
              <li>Сбор информации и&nbsp;управление оконечными устройствами</li>
              <li>Источником данных являются дискретные сигналы в&nbsp;виде напряжения 220v</li>
              <li>Модуль реализует функции диспетчеризации сухими контактами</li>
              <li>Информация с&nbsp;датчиков и&nbsp;команды управления реле передаются по промышленному интерфейсу MODBUS RTU с&nbsp;физическим уровнем RS485</li>
            </ul>
          `,
        },
      ],
    ],
    specifications: [
      {
        name: "Характеристики",
        data: [
          {
            type: "html",
            data: `
              <ul>
                <li>18&nbsp;входов датчика наличия 220v переменного тока;<br />Каждый вход является оптически изолированным от&nbsp;сигнальной части и&nbsp;измеряет наличие напряжения по&nbsp;отношению к&nbsp;нейтрали.</li>
                <li>12&nbsp;выходов сухих контактов (реле) Из&nbsp;них: 2&nbsp;выхода&nbsp;&mdash; контакт переключающий 2 выхода&nbsp;&mdash; контакт на&nbsp;размыкание 8&nbsp;выходов&nbsp;&mdash; контакт на&nbsp;замыкание;</li>
                <li>Интерфейс RS485&nbsp;MODBUS RTU;</li>
                <li>Вход питания контроллера 24v постоянного тока. Вход питания защищён от переполюсовки;</li>
                <li>Светодиодная индикация наличия питания и&nbsp;режимов работы.</li>
                <li>Требуемое состояние релейных выходов задаётся через интерфейс RS485&nbsp;MODBUS RTU</li>
                <li>Состояния входов считываются через интерфейс RS485&nbsp;MODBUS RTU</li>
              </ul>
            `,
          },
        ],
      },
    ],
  },
};
